import styles from "./About.module.scss";
import { ConfirmIcon, SearchIcon, SmileIcon } from "assets/icons";

const AboutInfo = () => {
  return (
    <div className={styles.about__info}>
      <div className={styles.info}>
        <div className={styles["for-img"]}>
          <img src={SearchIcon} />
        </div>
        <div>
          <h3>Browse and select</h3>
          <p>
            Choose from our wide range of premium cars, select the pickup and
            return dates and locations that suit you best.
          </p>
        </div>
      </div>
      <div className={styles.info}>
        <div className={styles["for-img"]}>
          <img src={ConfirmIcon} />
        </div>
        <div>
          <h3>Contact and confirm</h3>
          <p>
            Feel free to reach out to us by phone for booking confirmation.
            We're here to assist you.
          </p>
        </div>
      </div>
      <div className={styles.info}>
        <div className={styles["for-img"]}>
          <img src={SmileIcon} />
        </div>
        <div>
          <h3>Enjoy your ride</h3>
          <p>
            Pick up your car at the designated location and enjoy your premium
            driving experience with our top-quality service.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutInfo;
