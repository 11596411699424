import {
  ToyotaMarkImg,
  FordMarkImg,
  TeslaMarkImg,
  VolkswagenMarkImg,
  HondaMarkImg,
  NissanMarkImg,
  ChevroletMarkImg,
  BMWMarkImg,
} from "../../../assets/icons";

export const marksAuto = [
  {
    mark: "Toyota",
    img: ToyotaMarkImg,
  },
  {
    mark: "Ford",
    img: FordMarkImg,
  },
  {
    mark: "Tesla",
    img: TeslaMarkImg,
  },
  {
    mark: "Volkswagen",
    img: VolkswagenMarkImg,
  },
  {
    mark: "Honda",
    img: HondaMarkImg,
  },
  {
    mark: "Nissan",
    img: NissanMarkImg,
  },
  {
    mark: "Chevrolet",
    img: ChevroletMarkImg,
  },
  {
    mark: "BMW",
    img: BMWMarkImg,
  },
];
