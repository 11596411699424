import { CatalogsList } from "components";
import styles from "./Catalog.module.scss";
import { homeCatalogList } from "components/shared/Catalog/conts";

const Catalog = () => {
  return (
    <>
      <div className={styles.catalog__container}>
        <h2>Car Collection</h2>
        <CatalogsList catalogs={homeCatalogList} />
      </div>
    </>
  );
};

export default Catalog;
