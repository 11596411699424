import { About2Img } from "assets/img";
import styles from "./AboutInfo.module.scss";

const AboutInfo = () => {
  return (
    <div className={styles.aboutInfo}>
      <div>
        <img src={About2Img} alt='asdsad' />
        <div>
          <h2>Our Mission</h2>
          <p>
            At ARTSLS, our mission is clear: to redefine the car rental
            experience in Los Angeles by offering convenience, affordability,
            and personalized service. We aim to make every journey memorable,
            whether it's for business or leisure, by delivering exceptional
            rental solutions tailored to our customers' needs.
          </p>
          <h2>Core Values</h2>
          <ul>
            <li>
              Customer-Centric Approach: Our customers in Los Angeles are at the
              heart of everything we do. We prioritize their needs and
              satisfaction above all else.
            </li>
            <li>
              Reliability: You can trust ARTSLS to provide top-quality vehicles
              and dependable service every time you rent with us in Los Angeles.
            </li>
            <li>
              Transparency: We believe in transparent pricing and clear
              communication, ensuring that our customers in Los Angeles know
              exactly what to expect when they choose ARTSLS.
            </li>
          </ul>
        </div>
      </div>
      <h2>Services</h2>
      <p>
        Explore our diverse fleet of vehicles, ranging from compact cars to
        spacious SUVs, and find the perfect rental for your upcoming journey in
        Los Angeles. With flexible booking options and competitive rates, ARTSLS
        makes renting a car hassle-free and affordable in the City of Angels.
      </p>
      <h2>Meet the Team</h2>
      <p>
        Behind ARTSLS is a dedicated team of professionals committed to
        delivering the best possible experience for our customers in Los
        Angeles. Get to know the faces behind the service and discover the
        passion and expertise they bring to our company.
      </p>
      <h2>Community Engagement</h2>
      <p>
        At ARTSLS, we're proud to be active members of the Los Angeles
        community. From supporting local events to implementing eco-friendly
        initiatives, we're committed to making a positive impact in the City of
        Angels.
      </p>
    </div>
  );
};

export default AboutInfo;
