import { homeCatalogList } from "components/shared/Catalog/conts";
import { HomeAbout, Main, Marks, Service, Catalogs } from "components";

const Home = () => (
  <div>
    <Main />
    <Marks />
    <Catalogs catalogList={homeCatalogList} />
    <HomeAbout />
    <Service />
  </div>
);

export default Home;
