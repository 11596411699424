import React, { FC } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import styles from "./Modal.module.scss";
import { MailImg, PhoneCallImg } from "assets/img";
import useLockBodyScroll from "hooks/useLockBodyScroll";

interface IProp {
  isActive: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const Modal: FC<IProp> = ({ setIsActive, isActive }) => {
  const modalClass = classNames(styles.modal, {
    [styles.active]: isActive,
  });

  useLockBodyScroll(isActive);

  return (
    <div className={modalClass} onClick={() => setIsActive(false)}>
      <div onClick={(e) => e.stopPropagation()}>
        <div>
          <Link to={`tel: +1(323)-633-2000`}>
            <img src={PhoneCallImg} />
            <span>+1(323)-633-2000</span>
          </Link>
        </div>
        <div>
          <Link to={`mailto: artslstrans@gmail.com`}>
            <img src={MailImg} />
            <span>artslstrans@gmail.com</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Modal;
