import { FC } from "react";
import Header from "../Header";
import Footer from "../Footer";
import styles from "./Layout.module.scss";
import type { TLayoutProps } from "./types";

const Layout: FC<TLayoutProps> = ({ children }) => {
  return (
    <div className={[styles["for-sticky"], styles.container].join(" ")}>
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
