import { About1Img } from "assets/img";
import styles from "./About.module.scss";

const About = () => {
  return (
    <div className={styles.AboutUS}>
      <div>
        <h1>About US</h1>
        <h3>Welcome to ARTSLS Car Rentals in Los Angeles</h3>
        <p>
          At ARTSLS, we're dedicated to providing seamless and reliable car
          rental solutions to our valued customers in Los Angeles, California.
          Since our establishment in 2023, we've been serving the Los Angeles
          community and beyond with a focus on excellence and customer
          satisfaction.
        </p>
      </div>
      <img src={About1Img} alt='asdas' />
    </div>
  );
};

export default About;
