import MenuItems from "./MenuItems";
import classNames from "classnames";
import {useLockBodyScroll} from "hooks";
import styles from "./Navbar.module.scss";
import {useContext, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {ModalContext} from "../../../RoutesProvider";
import {Artsls, ArtslsCatalog} from "../../../assets/img/index";

const Navbar = () => {
    const context = useContext(ModalContext);
    const [isNavbarActive, setIsNavbarActive] = useState<boolean>(false);
    const {pathname} = useLocation();
    const className = pathname.split("/")[1];

    useLockBodyScroll(isNavbarActive);

    if (!context) {
        throw new Error("MyComponent must be used within a MyProvider");
    }

    const navContainerClass = classNames(styles["nav-container"], {
        container: true,
    });

    const setClassNames = (args: string[]) => {
        return args.map((className) => styles[className]).join(" ");
    };

    const onPopUp = () => {
        const {setIsModalActive} = context
        setIsModalActive(true);
    };

    return (
        <nav className={styles[className]}>
            <div className={styles.navbar}>
                <div className={navContainerClass}>
                    <div
                        className={styles["hamburger-lines"]}
                        onClick={() => {
                            setIsNavbarActive(!isNavbarActive);
                        }}
                    >
            <span
                className={setClassNames([
                    "line",
                    "line1",
                    className,
                    isNavbarActive ? "active" : "",
                ])}
            ></span>
                        <span
                            className={setClassNames([
                                "line",
                                "line3",
                                className,
                                isNavbarActive ? "active" : "",
                            ])}
                        ></span>
                    </div>
                    <div className={styles.logo}>
                        <Link to="/">
                            {className === "catalog" || className === "about" ? (
                                <img src={ArtslsCatalog}/>
                            ) : (
                                <img src={Artsls}/>
                            )}
                        </Link>
                    </div>
                    <button
                        onClick={onPopUp}
                        className={setClassNames(["btn", "popUp", className])}
                    >
                        Contact Us
                    </button>
                    <MenuItems
                        isNavbarActive={isNavbarActive}
                        setIsNavbarActive={setIsNavbarActive}
                    />
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
