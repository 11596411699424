import {useContext} from "react";
import styles from "./Main.module.scss";
import { MainImg } from "../../../assets/img";
import {ModalContext} from "../../../RoutesProvider";


const Main = () => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error("MyComponent must be used within a MyProvider");
  }

  const onPopUp = () => {
    const {setIsModalActive} = context
    setIsModalActive(true);
  };

  return (
    <>
      <main className={styles.container__main} id="Home">
        <h2>
          Stay tuned as we fine-tune our platform for an exhilarating car rental
          experience!
        </h2>
        <img src={MainImg} alt='main'/>
        <div>
          <p className={styles.p}>
            We provide driver services or car rental options. <br />
            Feel free to reach out to us.
          </p>
          <button onClick={onPopUp} className="btn popUp">
            Contact Us
          </button>
        </div>
      </main>
    </>
  );
};

export default Main;
