import {routes} from "../routes";
import {ScrollToTop} from "components";
import Layout from "components/shared/Layout";
import {createContext, FC, memo, useState} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Modal from "../components/shared/Modal";

interface MyContextType {
    setIsModalActive: (value: boolean) => void;
}

export const ModalContext = createContext<MyContextType | undefined>(undefined);

const RoutesProvider: FC = () => {
    const [isModalActive, setIsModalActive] = useState<boolean>(false);

    const routeElements = routes.map((route) => (
        <Route path={route.path} element={route.component} key={route.id}/>
    ));

    return (
        <BrowserRouter>
            <ModalContext.Provider value={{setIsModalActive}}>
                <Layout>
                    <ScrollToTop/>
                    <Routes>{routeElements}</Routes>
                </Layout>
                <Modal isActive={isModalActive} setIsActive={setIsModalActive}/>
            </ModalContext.Provider>
        </BrowserRouter>
    );
};

export default memo(RoutesProvider);
