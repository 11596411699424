import { FC } from "react";
import { ICatalogList } from "./conts";
import { Link } from "react-router-dom";
import CatalogsList from "./CatalogList";
import styles from "./Catalog.module.scss";

interface IProp {
  catalogList: ICatalogList[];
}

const Catalogs: FC<IProp> = ({ catalogList }) => {
  return (
    <div className={styles.catalog__container}>
      <h2>Discover Our Exceptional Car Collection</h2>
      <p>
        From sleek sedans to high-performance sports cars, each meticulously
        chosen to elevate our customers' driving experiences
      </p>
      <CatalogsList catalogs={catalogList.slice(0, 6)} />
      <button className="btn">
        <Link to="/catalog">See all Cars</Link>
      </button>
    </div>
  );
};

export default Catalogs;
