import {FC, useContext} from "react";
import {ICatalogList} from "./conts";
import styles from "./Catalog.module.scss";
import {ModalContext} from "../../../RoutesProvider";
import {gasStationIcon, manualGearboxIcon, usersIcon} from "assets/icons";

interface IProp {
    catalogs: ICatalogList[];
}

const CatalogsList: FC<IProp> = ({catalogs}) => {
    const context = useContext(ModalContext);

    if (!context) {
        throw new Error("MyComponent must be used within a MyProvider");
    }

    const onPopUp = () => {
    const {setIsModalActive} = context
        setIsModalActive(true);
    };


    return (
        <>
            <div className={styles.catalog}>
                {catalogs.map(({img, name, rent, id, rentTimeBy}) => (
                    <div key={id}>
                        <img src={img} alt='asdas'/>
                        <p>{name}</p>
                        <h4>
                            {rent}
                            <span>/{rentTimeBy}</span>
                        </h4>
                        <div>
                            <img src={manualGearboxIcon} alt='asdas'/>
                            <img src={usersIcon} alt='asdasda'/>
                            <img src={gasStationIcon} alt='asdasdasd'/>
                            <p>Auto</p>
                            <p>4 Person</p>
                            <p>Gasoline</p>
                        </div>
                        <button onClick={onPopUp} className="popUp">
                            Rent Now
                        </button>
                    </div>
                ))}
            </div>
        </>
    );
};

export default CatalogsList;
