import classNames from "classnames";
import { SetStateAction } from "react";
import { Link } from "react-router-dom";
import styles from "./Navbar.module.scss";

interface IProp {
  isNavbarActive: boolean;
  setIsNavbarActive: React.Dispatch<SetStateAction<boolean>>;
}

const MenuItems = ({ isNavbarActive, setIsNavbarActive }: IProp) => {
  const menuItem = classNames(styles["menu-items"], {
    [styles.active]: isNavbarActive,
  });

  const onClick = () => {
    setIsNavbarActive(false);
  };

  return (
    <div className={menuItem}>
      <li>
        <Link to="/" onClick={onClick}>
          Home
        </Link>
      </li>
      <li>
        <Link to="/about" onClick={onClick}>
          About
        </Link>
      </li>
      <li>
        <Link to="/catalog" onClick={onClick}>
          Collection
        </Link>
      </li>
    </div>
  );
};

export default MenuItems;
