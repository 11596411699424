import {
  AudiMarkImg,
  HyundaiMarkImg,
  KIAMarkImg,
  MercedesMarkImg,
} from "assets/icons";
import { marksAuto } from "./consts";
import styles from "./Marks.module.scss";

const Marks = () => {
  return (
    <div className={styles.marks}>
      <h2>Rent</h2>
      <div className={styles.marks__container}>
        {marksAuto.map(({ mark, img }) => (
          <div key={mark}>
            <img src={img} alt='1' />
            <h3>{mark}</h3>
          </div>
        ))}
        <div className={styles.none}>
          <img src={MercedesMarkImg} alt='2'  />
          <h3>Mercedes-Benz</h3>
        </div>
        <div className={styles.none}>
          <img src={HyundaiMarkImg} alt='3'  />
          <h3>Hyundai</h3>
        </div>
        <div>
          <img src={AudiMarkImg} alt='4'  />
          <h3>Audi</h3>
        </div>
        <div className={styles.none}>
          <img src={KIAMarkImg} alt='5' />
          <h3>KIA</h3>
        </div>
      </div>
    </div>
  );
};

export default Marks;
