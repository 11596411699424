import AboutInfo from "./AboutInfo";
import { AboutImg } from "assets/img";
import styles from "./About.module.scss";

const About = () => {
  return (
    <div className={styles.about}>
      <h2>How it woks</h2>
      <p>
        With our straightforward process, renting a car has never been easier.
        Experience convenience and reliability with ARTSLS today!
      </p>
      <div>
        <div className={styles.about__img}>
          <img src={AboutImg} alt='home' />
        </div>
        <AboutInfo />
      </div>
    </div>
  );
};

export default About;
