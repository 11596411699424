import Catalog from "containers/Catalog";
import { AboutUs, Error, Home } from "../containers";
import { Routes } from "../types/routes/routes";

export const routes = [
  {
    id: 1,
    component: <Home />,
    path: Routes.Home,
  },
  {
    id: 2,
    component: <Catalog />,
    path: Routes.Catalog,
  },
  {
    id: 3,
    component: <Error />,
    path: Routes.Error,
  },
  {
    id: 4,
    component: <AboutUs />,
    path: Routes.AboutUs,
  },
];
