import {
  AudiETronImg,
  AudiRs5Img,
  BMWM4Img,
  BMWM5Img,
  HyundaiElantraImg,
  HyundaiSantaFeImg,
} from "assets/img";

export const homeCatalogList: ICatalogList[] = [
  {
    id: 1,
    img: BMWM4Img,
    name: "BMW M4 Coupe",
    rent: "78.90",
    gearbox: "Auto",
    passengerNumber: "4",
    fuelType: "Gasoline",
    rentTimeBy: "hr",
  },
  {
    id: 2,
    img: AudiRs5Img,
    name: "Audi RS5",
    rent: "78.90",
    gearbox: "Auto",
    passengerNumber: "4",
    fuelType: "Gasoline",
    rentTimeBy: "day",
  },
  {
    id: 3,
    img: HyundaiElantraImg,
    name: "Hyundai Elantra Coupe",
    rent: "56.90",
    gearbox: "Auto",
    passengerNumber: "4",
    fuelType: "Gasoline",
    rentTimeBy: "day",
  },
  {
    id: 4,

    img: AudiETronImg,
    name: "Audi E-Tron",
    rent: "78.90",
    gearbox: "Auto",
    passengerNumber: "4",
    fuelType: "Gasoline",
    rentTimeBy: "day",
  },
  {
    id: 5,

    img: HyundaiSantaFeImg,
    name: "Hyundai Santa Fe Hybrid",
    rent: "78.90",
    gearbox: "Auto",
    passengerNumber: "4",
    fuelType: "Gasoline",
    rentTimeBy: "day",
  },
  {
    id: 6,

    img: BMWM5Img,
    name: "BMW 5-Series",
    rent: "67.90",
    gearbox: "Auto",
    passengerNumber: "4",
    fuelType: "Gasoline",
    rentTimeBy: "day",
  },
  {
    id: 7,

    img: BMWM4Img,
    name: "BMW M4 Coupe",
    rent: "78.90",
    gearbox: "Auto",
    passengerNumber: "4",
    fuelType: "Gasoline",
    rentTimeBy: "hr",
  },
  {
    id: 8,
    img: AudiRs5Img,
    name: "Audi RS5",
    rent: "78.90",
    gearbox: "Auto",
    passengerNumber: "4",
    fuelType: "Gasoline",
    rentTimeBy: "day",
  },
  {
    id: 9,
    img: HyundaiElantraImg,
    name: "Hyundai Elantra Coupe",
    rent: "56.90",
    gearbox: "Auto",
    passengerNumber: "4",
    fuelType: "Gasoline",
    rentTimeBy: "day",
  },
  {
    id: 10,
    img: AudiETronImg,
    name: "Audi E-Tron",
    rent: "78.90",
    gearbox: "Auto",
    passengerNumber: "4",
    fuelType: "Gasoline",
    rentTimeBy: "day",
  },
  {
    id: 11,
    img: HyundaiSantaFeImg,
    name: "Hyundai Santa Fe Hybrid",
    rent: "78.90",
    gearbox: "Auto",
    passengerNumber: "4",
    fuelType: "Gasoline",
    rentTimeBy: "day",
  },
  {
    id: 12,
    img: BMWM5Img,
    name: "BMW 5-Series",
    rent: "67.90",
    gearbox: "Auto",
    passengerNumber: "4",
    fuelType: "Gasoline",
    rentTimeBy: "day",
  },
];

export interface ICatalogList {
  id: number;
  img: string;
  name: string;
  rent: string;
  gearbox: string;
  passengerNumber: string;
  fuelType: string;
  rentTimeBy: string;
}
