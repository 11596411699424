import { About, AboutInfo } from "components";

const AboutUs = () => {
  return (
    <div>
      <About />
      <AboutInfo />
    </div>
  );
};

export default AboutUs;
