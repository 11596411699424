import "./Footer.scss";
import Modal from "../Modal";
import {useContext, useState} from "react";
import {Artsls} from "assets/img";
import {Link} from "react-router-dom";
import {ModalContext} from "../../../RoutesProvider";

const Footer = () => {
    const context = useContext(ModalContext);

    if (!context) {
        throw new Error("MyComponent must be used within a MyProvider");
    }

    const onPopUp = () => {
        const {setIsModalActive} = context;
        setIsModalActive(true);
    };

    return (
        <footer>
            <p>©2023 ARTSLS. All rights reserved</p>
            <Link to="/">
                <img src={Artsls}/>
            </Link>
            <ul>
                <li>
                    <Link to="/catalog">Collection</Link>
                </li>
                <li>
                    <Link to="/about">About</Link>
                </li>
                <li>
                    <a onClick={onPopUp} className="popUp">
                        Contact
                    </a>
                </li>
            </ul>
        </footer>
    );
};

export default Footer;
