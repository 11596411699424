import styles from "./Service.module.scss";
import { CheckIcon, ConinsHandsIcon, StarsIcon } from "assets/icons";

const Service = () => {
  return (
    <div className={styles.services}>
      <h2>Our Services & Benefits</h2>
      <p>
        To make renting easy and hassle-free, we provide a variety of services
        and advantages. We have you covered with a variety of vehicles and
        flexible rental terms.
      </p>
      <div>
        <div className={styles.service}>
          <div>
            <img src={StarsIcon}  alt='as'/>
          </div>
          <h3>Quality Choice</h3>
          <p>
            We offer a wide range of high-quality vehicles to choose from,
            including luxury cars, SUVs, vans, and more.
          </p>
        </div>
        <div className={styles.service}>
          <div>
            <img src={ConinsHandsIcon} alt='adsad' />
          </div>
          <h3>Affordable Prices</h3>
          <p>
            Our rental rates are highly competitive and affordable, allowing our
            customers to enjoy their trips without breaking the bank.
          </p>
        </div>
        <div className={styles.service}>
          <div>
            <img src={CheckIcon}  alt='asda'/>
          </div>
          <h3>24/7 Customer Support</h3>
          <p>
            Our dedicated customer support team is available round-the-clock to
            assist you with any inquiries, changes, or emergencies during your
            rental period.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Service;
